import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as Logo } from '../../logo-sgp-login.svg';
//import logo from './images/logo.png';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const { REACT_APP_API_ENDPOINT } = process.env;

console.log(REACT_APP_API_ENDPOINT)

const defaultTheme = createTheme();

export default function SignIn() {

  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [userNotFound, setUserNotFound] = useState(false);
  const [passwordErrata, setPasswordErrata] = useState(false);

   const imagerl = '/images/copernico-unsplash.jpg';

  const handleSubmit = async (event) => {
    event.preventDefault();
    /*
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
*/
setUserNotFound(false);
setPasswordErrata(false);

let bodyReq = {
    email: email,
    password: password,
    grant_type: 'password'
  }

const sendPostRequest = async () => {
    try {
        const resp = await axios.post(`${REACT_APP_API_ENDPOINT}/api/login/`, bodyReq, {
          header: {'Content-Type': 'application/json' }
        },
         
         );

        if(resp){
          //  console.log(resp.data.authuser);
            let tokenize = resp.data.authuser;
            let tokenObj= {
                _id: tokenize.id,
                cognome: tokenize.cognome,
                nome: tokenize.nome,
                email: tokenize.email,
                cellulare: tokenize.cellulare,
                telefono: tokenize.telefono,
                password: tokenize.password,
                role: tokenize.roles,
                token: tokenize.accessToken
              }
              localStorage.setItem('currentUser', JSON.stringify(tokenObj));


              if(tokenObj.role[0] === 'ROLE_OPERATORE' ){
                navigate("/operatore", { replace: true });
              }
              if(tokenObj.role[0] === 'ROLE_ADMIN' ){
                navigate("/admin", { replace: true });
              }
              if(tokenObj.role[0] === 'ROLE_CONSULENTE' ){
               // navigate("/consulente/tipo_pratica", { replace: true });
                navigate("/consulente", { replace: true });
              }
              if(!tokenObj){
                navigate("/", { replace: true });
              }


        }


    } catch (err) {

        console.error("Error response:");
        if(err.response.data.msg==='User not exist'){
            setUserNotFound(true);
        }

        if(err.response.data.msg ==='Invalid credencial'){
            setPasswordErrata(true);
        }

/*   console.error(err.response.data.msg);    
console.error(err.response.status); 
console.error(err.response.headers); */


       // if(err.message)
        
        // Handle Error Here
      //  console.log(err);
    }
};

sendPostRequest();

event.preventDefault();

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${imagerl})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div style={{marginLeft: '54px'}}>
      {/* Logo is an actual React component */}
      <Logo 
     height={80} 
     width={248} 
      />
    </div>


          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

   

            
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e=> setEmail(e.target.value)}
              />
              {
                userNotFound ? 
                <label htmlFor="usurnotfound" style={{color: 'red'}}>Utente non trovato</label>
                :null
               }
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e=> setPassword(e.target.value)}
              />
              {
                passwordErrata ? 
                <label htmlFor="password errata" style={{color: 'red'}}>password errata</label>
                :null
               }
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
